import axios from 'axios';
import Cookies from 'js-cookie';
import { getAuthToken } from '../utilities/helpers';

/* eslint-disable no-undef*/
/**
 * base instance and global settings for axios
 * @type {Object}
 */
const api = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8001' : '',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('GoogleToken')}`,
  },
  transformRequest: [
    (data, headers) => {
      return JSON.stringify(data);
    },
  ],
});

api.interceptors.request.use(config => {
  if (!Cookies.get('TokenActive')) {
    getAuthToken();
  }

  config.headers['Authorization'] = `Bearer ${Cookies.get('GoogleToken')}`;
  
  return config;
});

api.interceptors.response.use(
  response => response,
  error => {
    const originalConfig = error.config;

    if (error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;

      return getAuthToken().then(() => {
        return new Promise(resolve =>
          // has to wait for the auth to finish, for some reason it doesnt wait till the cookies are set
          setTimeout(function() {
            originalConfig.headers['Authorization'] = `Bearer ${Cookies.get('GoogleToken')}`;

            resolve(api(originalConfig));
          }, 3000)
        );
      });
    }
    return Promise.reject(error);
  }
);

export default api;
