import { transformUserData } from './utilities/helpers';
import api from '../index';
import Cookies from 'js-cookie';

/**
 * reads all rows for past hack google sheet
 * @return {Promise}
 */
export const getUser = () => {
  const token = Cookies.get('GoogleToken');
  const url = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`;
  return api({
    method: 'get',
    transformResponse: [data => transformUserData(JSON.parse(data))],
    url: url,
  });
};
