import { Link } from 'gatsby';
import React from 'react';

const Footer = () => {
  return (
    <div className="footer-div">
      <hr></hr>
      <div>
        <Link to="https://github.com/infoscout/Numerator-Hackday-Website">
          <button className="navBar-button">Github Repo</button>
        </Link>
      </div>
      <div>
        Deployed using Github Pages
      </div>
    </div>
  );
};

export default Footer;
