/* eslint-disable no-undef*/
import Cookies from 'js-cookie';

export async function getAuthToken(setToken) {
  const inFiftyFiveMinutes = new Date(new Date().getTime() + 55 * 60 * 1000);
  const inOneHour = new Date(new Date().getTime() + 60 * 60 * 1000);

  const SCOPES = 'email https://www.googleapis.com/auth/spreadsheets';

  const token_client = google.accounts.oauth2.initTokenClient({
    client_id: `${process.env.GOOGLE_CLIENT_ID}`,
    scope: SCOPES,
    hint: Cookies.get('UserEmail') ? Cookies.get('UserEmail') : '',
    callback: resp => {
      if (resp.error !== undefined) {
        throw resp;
      }

      Cookies.set('GoogleToken', resp.access_token, {
        expires: inOneHour,
      });
      Cookies.set('TokenActive', 'true', {
        expires: inFiftyFiveMinutes,
      });

      gapi.client.setToken(resp.access_token);

      if (setToken) {
        setToken(resp.access_token);
      }
    },
  });

  await token_client.requestAccessToken({ prompt: '' });

  if (token_client.error !== undefined) {
    console.warn(token_client.error);
  }
}
