import { Link } from 'gatsby';
import React, { useState } from 'react';

const Header = ({ handleSignOutClick }) => {
  const [toggleIdeaState, setToggleIdeaState] = useState('on');
  const [togglePastHackState, setTogglePastHackState] = useState('off');
  const [currPage, setCurrPage] = useState('ideaPage');

  function toggle(page) {
    if (page !== currPage) {
      setToggleIdeaState(toggleIdeaState === 'off' ? 'on' : 'off');
      setTogglePastHackState(togglePastHackState === 'off' ? 'on' : 'off');
      setCurrPage(page);
    }
  }

  function renderNumeratorLogoButton() {
    const page = 'indexPage';
    return (
      <div>
        <Link to="/" onClick={() => toggle(page)}>
          <img className="navBar-img" src="/images/numerator-logo.png" alt="Numerator" />
        </Link>
      </div>
    );
  }

  function renderHackdayIdeasPageButton() {
    const page = 'ideaPage';
    return (
      <div className="navBar-button-wrap">
        <Link to="/current-hackday">
          <button className="navBar-button" onClick={() => toggle(page)}>
            Current HackDay
          </button>
        </Link>
      </div>
    );
  }

  function renderPreviousHackdayPageButton() {
    const page = 'previousHacksPage';
    return (
      <div className="navBar-button-wrap">
        <Link to="/previous-hackdays">
          <button className="navBar-button" onClick={() => toggle(page)}>
            Past Presentations
          </button>
        </Link>
      </div>
    );
  }

  function renderFAQPageButton() {
    const page = 'faqPage';
    return (
      <div className="navBar-button-wrap">
        <Link to="/faq">
          <button className="navBar-button" onClick={() => toggle(page)}>
            FAQ
          </button>
        </Link>
      </div>
    );
  }

  function renderRulesPageButton() {
    const page = 'rulesPage';
    return (
      <div className="navBar-button-wrap">
        <Link to="/rules">
          <button className="navBar-button" onClick={() => toggle(page)}>
            Rules
          </button>
        </Link>
      </div>
    );
  }

  function renderRubricPageButton() {
    const page = 'rubricPage';
    return (
      <div className="navBar-button-wrap">
        <Link to="/rubric">
          <button className="navBar-button" onClick={() => toggle(page)}>
            Rubric
          </button>
        </Link>
      </div>
    );
  }

  function renderSignOutButton() {
    return (
      <button onClick={() => handleSignOutClick()} className={`auth-btn signed-in-auth-btn`}>
        Sign Out
      </button>
    );
  }

  return (
    <div className="navBar">
      {renderNumeratorLogoButton()}
      <div className="navBar-button-container">
        {renderHackdayIdeasPageButton()}
        {renderPreviousHackdayPageButton()}
        {renderRulesPageButton()}
        {renderRubricPageButton()}
        {renderFAQPageButton()}
        {renderSignOutButton()}
      </div>
    </div>
  );
};

export default Header;
